<template>
  <div class="form-container pb-15 px-5">
    <h1 class="text-heading--prm text-center pt-10 pb-8">
      {{ $t("menu.main.exchangePoints") }}
    </h1>

    <h2 class="text-heading--prm text-center mb-4">
      {{ $t("giftTicket.applicationDetails") }}
    </h2>
    <!--=========== START Gift-ticket-order-list ===========-->
    <GiftTicketItem
      v-for="item in cartItems"
      isSaleItem
      :key="item.id"
      :pointsRequired="item.total"
      :name="item.product.name"
      :place="item.product.companyName"
      :totalTickets="item.quantity"
      :saleEndAt="item.product.saleEndAt"
    />
    <!--=========== END Gift-ticket-order-list ===========-->

    <!-- Number-of-points-after-exchange -->
    <div
      class="border-bold-b --border-prm d-flex justify-center align-end pb-5 --c-prm"
    >
      <label class="text-medium --prm-light mr-5">
        {{ $t("giftTicket.numberOfPointsAfterExchange") }}
      </label>
      <span class="text-large-szlx --prm-light font-weight-bold mb-n2">
        {{ (this.availablePoints - this.cartTotal) | toThousands }}pt
      </span>
    </div>
    <!-- ------------------------------- -->
    <!--=========== START Addressee ===========-->
    <gift-ticket-order-address />
    <!-- Order-button -->
    <v-btn
      elevation="0"
      @click="submitForm"
      x-large
      rounded
      block
      class="bg-primary-light text-heading--white mt-3"
    >
      {{ $t("menu.main.items.orderTickets") }}
    </v-btn>
    <!-- ------------- -->

    <!-- Return-button -->
    <v-btn
      elevation="0"
      @click="$router.go(-1)"
      x-large
      rounded
      block
      class="bg-silver text-heading--prm-light font-weight-bold mt-4"
    >
      {{ $t("buttons.return") }}
    </v-btn>
    <!-- ------------- -->
    <!--=========== END Addressee ===========-->
    <CommonModal :hasCloseButton="false" :visible="orderComplete">
      <template>
        <div class="px-6 py-4">
          <h4 class="mt-2 mb-5 --w-bold text-heading --prm">
            {{ $t("giftTicket.orderCompleteTitle") }}
          </h4>
          <div class="px-3">
            <router-link to="/home/exchange-points/history">
              <v-btn
                v-if="$isAllowed().pointExchangeHistory()"
                elevation="0"
                rounded
                height="57"
                outlined
                class="bg-white text-normal --w-bold --prm-light w-100"
              >
                {{ $t("menu.main.items.receptionStatusOrHistory") }}
              </v-btn>
            </router-link>
            <router-link to="/home">
              <v-btn
                elevation="0"
                rounded
                height="57"
                outlined
                class="bg-white text-normal --w-bold --prm-light w-100 mt-3"
              >
                {{ $t("buttons.backToTop") }}
              </v-btn>
            </router-link>
          </div>
        </div>
      </template>
    </CommonModal>
  </div>
</template>

<script>
import GiftTicketItem from '../../../components/GiftTicketItem'
import { checkPhoneNumber } from '@/utils/validate'
import mixin from '../mixin'
import CommonModal from '../../../components/Modal/Common'
import GiftTicketOrderAddress from './GiftTicketOrderAddress.vue'

export default {
  name: 'GiftTicketOrder',
  mixins: [mixin],
  components: {
    CommonModal,
    GiftTicketItem,
    GiftTicketOrderAddress
  },
  methods: {
    async submitForm () {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('orderPointExchangeProducts', {
            name: this.name,
            tel: this.phoneNumber,
            email: this.email
          })
          this.orderComplete = true
        })
      })
    },
    orderCompleteConfirm () {
      this.$router.push('/home/exchange-points')
    }
  },
  async created () {
    if (!this.$store.getters.getPointExchangeCartList.length) await this.$router.push('/home/exchange-points')
  },
  data () {
    return {
      name: null,
      phoneNumber: null,
      email: null,
      orderComplete: false,
      rules: {
        name: [
          v => !!v || this.$t('rules.isRequired'),
          v => v?.trim().length < 25 || this.$t('rules.invalid')
        ],
        phoneNumber: [
          v => !!v || this.$t('rules.isRequired'),
          v => checkPhoneNumber(v) || this.$t('rules.invalid')
        ]
      }
    }
  }
}
</script>
