<template>
  <div class="px-5">
    <h2 class="text-heading--prm-light --w-bold mt-14">
      {{ $t("common.addressee") }}
    </h2>
    <p class="text-medium--gray-dark mt-2 mb-7">
      {{ $t("giftTicket.orderNoticeSending") }}
    </p>
    <v-form ref="form" lazy-validation>
      <label for="name">{{ $t('common.yourName') }}</label>
      <v-text-field
        id="name"
        :value="address.name"
        readonly
        x-large
        solo
        flat
        type="text"
        class="mt-2"
      />

      <label for="phoneNumber">{{ $t('common.phoneNumber') }}</label>
      <v-text-field
        id="phoneNumber"
        :value="address.tel"
        readonly
        placeholder="000-123-1234"
        x-large
        solo
        flat
        type="text"
        class="mt-2"
      />

      <label for="address">{{ $t('common.sendingAddress') }}</label>
      <v-textarea
        id="address"
        :value="getAddressString(address)"
        readonly
        :height="1"
        auto-grow
        typeof="email"
        x-large
        solo
        flat
        type="text"
        class="mt-2"
      />
    </v-form>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { getAddressString } from '@/utils/string'
export default {
  data () {
    return {
      address: {}
    }
  },
  methods: {
    getAddressString (address) {
      return getAddressString(address, { noPostalCode: true })
    }
  },
  apollo: {
    address: {
      query: gql`query getDefaultPointExchangeAddress { 
        defaultPointExchangeAddress {
          name
          tel
          email
          postalCode
          prefecture
          locality
          address1
          address2
        }
       }`,
      update: data => data.defaultPointExchangeAddress
    }
  }
}
</script>
